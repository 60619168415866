@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.stickyNavbar {
  position: fixed;
  top: 0px;
  z-index: 49;
}

.page-item.active .page-link {
  z-index: 30;
  color: #fff !important;
  background-color: blue !important;
}

/* Custom Scrollbar Styles */
/* Track */
::-webkit-scrollbar {
  width: 8px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: #807c7c;
  border-radius: 6px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: #302f2f;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}
